import { initializeBurgerMenu } from './js/burgerLogic';

document.addEventListener('DOMContentLoaded', () => {
  initializeBurgerMenu();


  const links = document.querySelectorAll('.navLink');

  const url = window.location.pathname

  links.forEach(link  => {

    if(link.getAttribute('href') === url) {
      link.classList.add('active')
  }
}); 
});
